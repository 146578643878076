<template>
  <div>
    <div class="view pa24 mr mb20">
      <span style="color: #8d97ad; font-size: 14px">发布流程：1.选择商品分类 ------ 2.输入商品基本信息 ------
        3.输入商品详情 ------ 4.发布商品</span>
    </div>

    <el-form ref="form" :model="form" :rules="rules" label-width="auto" class="view pa24" label-position="left">
      <p class="cA2 fwbold fs8 mb20">1.选择商品类型</p>
      <el-form-item prop="goodstypeId" label="商品分类">
        <el-cascader expand-trigger="hover" :props="props" :options="cascaderData" v-model="goodstypeId"
          @change="chnagegoodstype">
        </el-cascader>
        <el-button type="primary" class="ma ml10" @click="open">添加分类</el-button>
      </el-form-item>
      <hr style="
          background-color: #e4e4e4;
          border: none;
          height: 1px;
          margin: 20px 0;
        " />
      <p class="cA2 fwbold fs8 mb20">2.输入商品基本信息</p>
      <el-form-item label="商品类型">
        <el-switch v-model="form.isReal" active-text="实物" inactive-text="虚拟">
        </el-switch>
      </el-form-item>
      <el-form-item prop="goodsName" label="商品标题">
        <el-input v-model="form.goodsName" style="width: 350px" placeholder="请选择商品名称" maxlength="30" show-word-limit>
        </el-input>
      </el-form-item>
      <el-form-item prop="stockNum" label="商品库存">
        <el-input v-model="form.stockNum" oninput="value=value.replace(/[^\d]/g,'')" type="number" style="width: 350px"
          placeholder="请选择商品库存" show-word-limit maxlength="10"></el-input>
      </el-form-item>

      <el-form-item prop="goodSpecModelList" label="商品规格">
        <div style="
            border: 1px solid #e4e4e4;
            width: 680px;
            border-radius: 5px;
            padding: 10px 20px;
          " ref="addEl">
          <template v-for="(item, index) in form.goodSpecRequestList ||
            form.goodSpecModelList">
            <div :key="index" style="margin-top: 10px">

              <el-input type="text" placeholder="请输入商品类型名称" v-model="item.specName" style="width: 380px" /><span
                class="hover_pointer" @click="deleteName(index)" style="color: #51cdcb; margin-left: 5px">删除</span>
              <span style="margin-left: 5px;">
                <el-switch v-model="item.requiredSelect" active-text="必选" inactive-text="">
                </el-switch>
              </span>
              <template v-for="(attr, index1) in item.list || item.goodSpecModelList">
                <div :key="index1" style="margin-top: 5px">
                  <el-input type="text" placeholder="请输入商品规格" v-model="attr.specAttribute" style="width: 150px"
                    @blur="specAttribute = attr.specAttribute" />
                  <el-input v-model="attr.price" class="suffixInput" type="number" placeholder="价格" style="width: 180px"
                    @change="() => {
                      attr.price = attr.price < 0.01 ? '' : (attr.price * 1).toFixed(2)
                    }">
                    <template slot="append">价格(元)</template>
                  </el-input>
                  <el-input v-model="attr.costPrice" class="suffixInput" type="number" placeholder="成本价"
                    style="width: 200px" @change="() => {
                      attr.costPrice = attr.costPrice < 0.01 ? '' : (attr.costPrice * 1).toFixed(2)
                    }"><template slot="append">成本价(元)</template>
                  </el-input>
                  <span class="hover_pointer" @click="deleteAttribute(index, index1)"
                    style="color: #51cdcb; margin-left: 5px; margin-right: 5px">删除</span><span class="hover_pointer"
                    @click="addAttribute(index)" style="color: #51cdcb">添加属性</span>
                </div>
              </template>
            </div>
          </template>
          <span class="hover_pointer" @click="addSpec()" style="color: #51cdcb; margin-top: 10px">
            添加规格
          </span>
        </div>
      </el-form-item>

      <el-form-item prop="goodPhoto" label="商品图片">
        <p class="ma mb10" style="color: rgb(150, 150, 150)">
          上传图片的最佳尺寸：800像素*800像素，其他尺寸会影响页效果，格式png，jpeg，jpg。大小不超过4M，商品图片一共可以上传5张，默认第一张为主图封面
        </p>
        <div style="display: flex">
          <el-upload :action="uploadUrl" :class="{
            disabled: uploadDisabled,
          }" list-type="picture-card" :on-preview="handlePictureCardPreview" :on-remove="handleRemove"
            :on-success="goodsUpload" :file-list="form.goodPhoto" :limit="5">
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :visible.sync="dialogVisible"
            append-to-body>
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </div>
      </el-form-item>
      <hr style="
          background-color: #e4e4e4;
          border: none;
          height: 1px;
          margin: 20px 0;
        " />
      <el-form-item prop="coverImg" label="分享图片">
        <p class="ma mb10" style="color: rgb(150, 150, 150)">
          上传图片及支持png/jpg 。显示图片长宽比为5:4
        </p>
        <div>
          <el-upload :action="uploadUrl" :class="{
            disabled: uploadDisabled2,
          }" list-type="picture-card" :on-preview="handlePictureCardPreview" :on-remove="handleRemove2"
            :on-success="shareUpload" :file-list="form.coverImg" :limit="1">
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :visible.sync="dialogVisible"
            append-to-body>
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </div>
      </el-form-item>
      <hr style="
          background-color: #e4e4e4;
          border: none;
          height: 1px;
          margin: 20px 0;
        " />
      <el-form-item label="是否参加分销" label-width="130px">
        <el-radio-group v-model="form.isDistribution">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="是否展示分销语" label-width="130px" v-if="form.isDistribution">
        <el-radio-group v-model="form.isHiddenTitle">
          <el-radio :label="0">是</el-radio>
          <el-radio :label="1">否</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="是否发布到产品中心" label-width="150px">
        <el-radio-group v-model="form.isPublic">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>

      <p class="cA2 fwbold fs8 mb20">3.支付方式</p>
      <el-form-item label="支付方式">
        <el-radio-group v-model="form.ispayment">
          <el-radio :label="1">线上支付</el-radio>
          <el-radio :label="0">其他</el-radio>
        </el-radio-group>
      </el-form-item>

      <p class="cA2 fwbold fs8 mb20">4.输入商品详情</p>
      <el-form-item label-width="0px">
        <p class="ma mb10">商品详情</p>
        <!-- //富文本 -->
        <quill-editor style="height: 100%" v-model="form.goodsDetails" ref="myQuillEditor" :options="editorOption" />
      </el-form-item>

      <el-form-item label-width="0px">
        <el-button type="primary" size="medium" @click="addGood">发布</el-button>

        <el-button size="medium" @click="isShow = true">预览</el-button>
      </el-form-item>
      <prevGoods :isShow="isShow" :prod_imgs="form.goodPhoto"
        :prod-size="form.goodSpecRequestList || form.goodSpecModelList" :edit_html="form.goodsDetails" @close="prodClose">
      </prevGoods>
    </el-form>
    <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="添加分类" :visible.sync="addTypeVisible"
      :destroy-on-close="true" width="500px" @close="closeFun">
      <el-form ref="typeFormData" :rules="addTypeRules" :model="typeFormData" label-width="80px">
        <el-form-item label="图标" prop="typeIcon">
          <el-upload class="iconUpload" :action="$store.state.uploadingUrl" list-type="picture-card"
            :class="{ disabled: uploadDisabled3 }" :limit="1" :on-progress="linkmanImgProgress"
            :on-success="linkmanImgSuccess" :on-remove="linkmanImgRemove" :file-list="typeIcon">
            <i class="el-icon-plus"></i>
          </el-upload>
          <div class="h100p d-flex w200 align-items-center">
            <p>最多只能上传1张，建议上传100x100，格式可为png、jpg或jpeg</p>
          </div>
        </el-form-item>
        <el-form-item label="大分类">
          <el-select v-model="typeFormData.dictValue" placeholder="请选择大分类">
            <el-option :label="row.dictLabel" :value="row.dictValue" v-for="(row, key) in maxGoodsTypeData" :key="key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分类名称" prop="name">
          <el-input v-model="typeFormData.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addTypeVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitType">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// @ts-ignore
import {
  selectGoodsTypeInfoPC,
  addGoodsType,
  getGoodsInfo,
  addGoods,
  getByDictType
} from "@/api/coupon.js";
import prevGoods from './components/prevGoods'
import { quillEditor, Quill } from "vue-quill-editor"; //
import resizeImage from "quill-image-resize-module"; // 调整大小组件。
import { ImageDrop } from "quill-image-drop-module"; // 拖动加载图片组件
import quillConfig from "@/utils/quill-config.js";
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/resizeImage ", resizeImage);
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
quillConfig.placeholder = '请填写商品内容'
var Align = Quill.import('attributors/style/align');
Align.whitelist = ['right', 'center', 'justify'];
Quill.register(Align, true);

export default {
  data() {
    var validatePass2 = (rule, value, callback) => {
      //-console.log(this.form)
      if (this.form.goodSpecModelList[0] || this.form.goodSpecRequestList[0]) {
        callback();
      } else {
        callback(new Error("请输入商品属性"));
      }
    };
    var valiGoodPhoto = (rule, value, callback) => { // 图片验证
      if (this.form.goodPhoto.length == 0) {
        callback();
      } else {
        callback();
      }
    };
    var valiCoverImg = (rule, value, callback) => { // 图片验证
      if (this.form.coverImg.length == 0) {
        callback();
      } else {
        callback();
      }
    };
    return {
      addTypeVisible: false,//是否显示添加分类
      typeFormData: {//提交分类数据
        typeIcon: "",
        dictLabel: "",
        name: ""
      },
      typeIcon: [],
      addTypeRules: {//添加分类验证
        typeIcon: {
          required: true,
          message: "请上传分类图标",
        },
        name: {
          required: true,
          message: "请输入分类名称",
          trigger: "blur",
        },
      },
      props: {
        lazy: true,
        checkStrictly: true,
        lazyLoad: async (node, resolve) => {
          let resultData = [];
          if (node.level == 2) {
            let res = await selectGoodsTypeInfoPC({
              goodstypeId: node.value,
              isPage: false,
              showParent: 0
            });
            if (res.data) {
              for (let row of res.data) {
                resultData.push({
                  value: row.goodstypeId,
                  label: row.name,
                  leaf: true
                });
              }
            }
          }
          resolve(resultData);
        }
      },
      cascaderData: [],
      goodstypeId: [],
      maxGoodsTypeData: [],
      goodsType: [], //分类渲染
      editorOption: quillConfig,
      isShow: false,
      form: {
        goodsName: "", //商品名称
        stockNum: "", //库存
        goodstypeId: "", //类型ID
        goodsDetails: "", //商品详情
        ispayment: 1, //支付方式
        isDistribution: 0, //分销售
        isReal: false,
        isPublic: false, //上架
        goodPhoto: [], //商品图片
        coverImg: [],
        isHiddenTitle: 0,
        //商品规格
        specAttribute: '',
        goodSpecRequestList: [
          {
            specName: "", //规格名称
            list: [
              {
                specAttribute: "", //规格属性（价格）
                specNum: "1", //规格数量
                costPrice: 0,
                price: 0, //价格除100
                requiredSelect: false
              },
            ],
          },
        ],
        goodSpecModelList: [],
      },
      rules: {
        goodstypeId: [{ required: true, message: "请选择分类！" }],
        goodsName: [
          {
            required: true,
            message: "请输入产品标题！",
            trigger: "change",
          },
        ],
        stockNum: [{ required: true, message: "请输入库存", trigger: "blur" }
        ],
        goodSpecModelList: [{ required: true, validator: validatePass2, trigger: "change" }],
        goodsDetails: [
          { required: true, message: "请输入描述", trigger: "change" },
        ],
        goodPhoto: [{ required: true, validator: valiGoodPhoto, trigger: "change" }],
        coverImg: [{ required: true, validator: valiCoverImg, trigger: "change" }],
      },
      uploadUrl: this.$store.state.uploadingUrl,
      ossUrl: this.$store.state.ossUrl,
      dialogImageUrl: "",
      dialogVisible: false,
      indexs: "",
    };

  },
  components: {
    prevGoods,
    quillEditor
  },
  computed: {
    uploadDisabled3() {
      return this.typeFormData.typeIcon !== "";
    },
    uploadDisabled() {
      return this.form.goodPhoto.length >= 5;
    },
    uploadDisabled2() {
      return this.form.coverImg && this.form.coverImg.length >= 1;
    },
  },
  async created() {
    this.goodsId = this.$route.query.id;
    await this.getGoodsType();
    if (this.$route.query.id) {
      const result = await getGoodsInfo({ goodsId: this.$route.query.id });
      let results = JSON.parse(result.data.pageInfo).list[0];
      if (results.goodPhoto) {
        results.goodPhoto = results.goodPhoto.split(",").map((item, index) => {
          return {
            url: item,
            uid: index,
          };
        });
      }
      if (results.parentId) {
        for (let row of this.cascaderData) {
          for (let child of row.children) {
            if (child.value == results.parentId) {
              child.children = [{
                label: results.goodsTypeName,
                value: results.goodstypeId
              }];
              break;
            }
          }
        }
      }
      if (results.coverImg && results.coverImg.length !== 2 && results.coverImg.length !== 0) {
        results.coverImg = [{ url: results.coverImg, uid: 1 }];
      } else {
        results.coverImg = [];
      }
      this.cascaderData=JSON.parse(JSON.stringify(this.cascaderData))
     //console.log(this.cascaderData);
      this.form = results;
      // if (results.parentId) {
      //   this.goodstypeId = [String(6), results.parentId, results.goodstypeId]
      // } else if (results.dictValue) {
      //   this.goodstypeId = [String(results.dictValue), results.goodstypeId]
      // } else {
      //   this.goodstypeId = [results.goodstypeId]
      // }
      this.goodstypeId = results.goodstypeId;
     //console.log(this.goodstypeId);
      this.form.goodSpecModelList.forEach((val, k) => {
        for (var i in val.goodSpecModelList) {
          val.goodSpecModelList[i].costPrice = val.goodSpecModelList[i].costPrice / 100;
          val.goodSpecModelList[i].price = val.goodSpecModelList[i].price / 100;
          this.specAttribute = val.goodSpecModelList[i].specAttribute
          //-console.log(this.specAttribute)
        }
      });
    }
  },

  methods: {
    closeFun() {
      this.typeFormData.typeIcon = "";
      this.typeIcon = [];
    },
    /**@method 删除图片 */
    linkmanImgRemove(file, fileList) {
      //-console.log(file, fileList);
      this.typeFormData.typeIcon = "";
      this.typeIcon = [];
    },
    /**@method 图片上传 */
    linkmanImgProgress(event, file, fileList) {

    },
    /**@method 上传回调 */
    linkmanImgSuccess(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.typeFormData.typeIcon = `https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/${response.data}`;
    },
    chnagegoodstype(data) {
      this.form.goodstypeId = data[data.length - 1]
    },
    getGoodsType() {
      return Promise.all([
        new Promise(async open => {
          let res = await getByDictType({ dictType: "max_goods_type", status: 0 })
          if (res.code === 200) {
            this.maxGoodsTypeData = res.data ? res.data.sysDictDataList : [];
          }
          this.maxGoodsTypeData.unshift({
            dictLabel: "其他",
            dictValue: "other",
          });
          open();
        }),
        new Promise(async open => {
          let goodsTypes = await selectGoodsTypeInfoPC({
            isPage: false,
            showParent: 1
          });//获取小分类
          if (goodsTypes.data) {
            this.goodsType = goodsTypes.data ? goodsTypes.data : [];
          }
          open();
        })
      ]).then(() => {
        let cascaderData = [];
        let goodsTypeObj = {};
        for (let row of this.goodsType) {
          let obj = {
            dictValue: row.dictValue,
            dictLabel: row.dictLabel,
            value: row.goodstypeId,
            label: row.name
          }
          if (obj.dictValue) {
            if (goodsTypeObj[obj.dictValue]) {
              goodsTypeObj[obj.dictValue].push(obj);
            } else {
              goodsTypeObj[obj.dictValue] = [obj];
            }
          } else {
            if (goodsTypeObj['other']) {
              goodsTypeObj['other'].push(obj);
            } else {
              goodsTypeObj['other'] = [obj];
            }
          }
        }
        for (let row of this.maxGoodsTypeData) {
          let obj = {
            value: row.dictValue,
            label: row.dictLabel,
          };
          if (goodsTypeObj[row.dictValue] && goodsTypeObj[row.dictValue].length > 0) {
            obj.children = goodsTypeObj[row.dictValue];
          }
          cascaderData.push(obj);
        }
        this.cascaderData = cascaderData;
      })
    },
    delImg(index) {
      this.form.goodPhoto.splice(index, 1);
    },

    open1() {
      this.$alert(
        `
      <div style="color:#F56C6C">
          <div>
      </div>`,
        {
          dangerouslyUseHTMLString: true,
          // showClose: false,
          showonConfirmButton: true,
          distinguishCancelAndClose: false,
        }
      );
    },

    addSpec: function () {
      if (!this.$route.query.id) {
        this.form.goodSpecRequestList.push({
          specName: "",
          list: [
            {
              specAttribute: "",
              specNum: 1,
              price: 0,
              costPrice: 0
            },
          ],
        });
      } else {
        this.form.goodSpecModelList.push({
          specName: "",
          goodSpecModelList: [
            {
              specAttribute: "",
              specNum: 1,
              price: 0,
              costPrice: 0
            },
          ],
        });
      }
    },

    deleteAttribute: function (index, index1) {
      if (!this.$route.query.id) {
        this.form.goodSpecRequestList[index].list.splice(index1, 1);
      } else {
        this.form.goodSpecModelList[index].goodSpecModelList.splice(index1, 1);
      }
    },

    deleteName: function (index) {
      if (!this.$route.query.id) {
        this.form.goodSpecRequestList.splice(index, 1);
      } else {
        this.form.goodSpecModelList.splice(index, 1);
      }
    },

    addAttribute: function (index) {
      if (!this.$route.query.id) {
        this.form.goodSpecRequestList[index].list.push({
          specAttribute: "",
          specNum: 1,
          price: 0,
          costPrice: 0
        });
      } else {
        this.form.goodSpecModelList[index].goodSpecModelList.push({
          specAttribute: "",
          specNum: 1,
          price: 0,
          costPrice: 0
        });
      }
    },

    onSubmit() {
      //-console.log("submit!");
    },

    handleRemove(file, fileList) {
      this.form.goodPhoto = fileList;
    },
    handleRemove2(file, fileList) {
      this.form.coverImg = fileList;
    },
    //新增商品
    addGood() {
      //-console.log(this.form.goodSpecRequestList);
      //-console.log(this.form.goodSpecModelList);
      // return;
      if (this.form.goodPhoto.length == 0) {
        return this.$message({
          message: "商品图片未上传",
          type: "warning",
          center: true,
        });
      }
      if (this.form.coverImg.length == 0) {
        return this.$message({
          message: "商品分享图片未上传",
          type: "warning",
          center: true,
        });
      }
      let err = ''
      //-console.log(this.$refs.form);
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let goodSpecRequestList = [];
          let goodSpecModelList = [];
          if (this.form.goodSpecRequestList) {
            for (let row of this.form.goodSpecRequestList) {
              if (!row.specName) {
                err = true
                break;
              }
              let sku = [];
              for (let child of row.list) {
                if (!child.price || !child.specAttribute) {
                  err = true
                  break
                }
                sku.push({
                  costPrice: child.costPrice * 100,
                  price: child.price * 100,
                  requiredSelect: child.requiredSelect,
                  specAttribute: child.specAttribute,
                  specNum: child.specNum,
                });
              }
              goodSpecRequestList.push({
                list: sku,
                specName: row.specName
              });
              if (err) {
                break
              }
            }
            // 旧方法 提交错误时出现二个0
            // goodSpecRequestList.forEach((val, k) => {
            //   if (!val.specName) {
            //     err = true
            //     return
            //   }
            //   for (var i in val.list) {
            //     if (!val.list[i].price || !val.list[i].specAttribute) {
            //       err = true
            //       break
            //     }
            //     val.list[i].price = val.list[i].price * 100;
            //     val.list[i].costPrice = val.list[i].costPrice * 100;
            //   }
            // });
          } else {
            //重新赋值为新数组
            for (let row of this.form.goodSpecModelList) {
              let sku = [];
              for (let child of row.goodSpecModelList) {
                if (!child.price || !child.specAttribute) {
                  err = true
                  break
                }
                sku.push({
                  costPrice: child.costPrice * 100,
                  end: child.end,
                  goodSpecModelList: child.goodSpecModelList,
                  goodsId: child.goodsId,
                  isDel: child.isDel,
                  parentId: child.parentId,
                  price: child.price * 100,
                  requiredSelect: child.requiredSelect,
                  specAttribute: child.specAttribute,
                  specId: child.specId,
                  specNum: child.specNum,
                  start: child.start,
                  state: child.state,
                });
              }
              goodSpecModelList.push({
                costPrice: row.costPrice,
                end: row.end,
                goodSpecModelList: sku,
                goodsId: row.goodsId,
                isDel: row.isDel,
                parentId: row.parentId,
                requiredSelect: row.requiredSelect,
                specId: row.specId,
                specName: row.specName,
                start: row.start,
                state: row.state,
              });
              if (err) {
                break;
              }
            }
            // 旧方法 提交错误时出现二个0
            // goodSpecModelList.forEach((val, k) => {
            //   for (var i in val.goodSpecModelList) {
            //     if (!val.goodSpecModelList[i].price || !val.goodSpecModelList[i].specAttribute) {
            //       err = true
            //       break
            //     }
            //     val.goodSpecModelList[i].price = val.goodSpecModelList[i].price * 100;
            //     val.goodSpecModelList[i].costPrice = val.goodSpecModelList[i].costPrice * 100;
            //   }
            // });
          }
          if (err) {
            this.$message.error('请完善商品规格')
            return
          }
          let arr = this.form.goodPhoto.map((item) => item.url);

          let data = {
            goodsName: this.form.goodsName, //商品名称
            stockNum: this.form.stockNum, //库存
            goodstypeId: this.form.goodstypeId, //类型ID
            goodsDetails: this.form.goodsDetails, //商品详情
            ispayment: this.form.ispayment, //支付方式
            isDistribution: this.form.isDistribution, //分销售
            isReal: this.form.isReal,
            isPublic: this.form.isPublic, //上架
            goodPhoto: arr.join(","), //商品图片
            goodsId: this.goodsId,
            isHiddenTitle: this.form.isHiddenTitle, //是否展示分销语
            coverImg:
              this.form.coverImg && this.form.coverImg.length != 0
                ? this.form.coverImg[0].url
                : [],
            goodSpecRequestList: goodSpecRequestList
          };
          if (this.$route.query.id) {
            data.goodSpecRequestList = goodSpecModelList.map((row) => {
              return {
                specName: row.specName,
                specId: row.specId,
                requiredSelect: row.requiredSelect,
                list: row.goodSpecModelList.map((item) => {
                  return {
                    specAttribute: item.specAttribute,
                    specNum: item.specNum,
                    price: item.price,
                    costPrice: item.costPrice,
                    specId: item.specId,
                  };
                }),
              };
            });
          }
          if (this.$route.query.id) {
            addGoods(data)
              .then(() => {
                this.$message({
                  message: "操作成功",
                  type: "success",
                  center: true,
                });
                setTimeout(() => {
                  this.$router.go(-1);
                }, 1000);
              })
              .catch(err => {
                if (err.code === 201) {
                  this.$message({
                    message: err.message,
                    type: "warning",
                    center: true,
                  });
                }
              });
          } else {
            addGoods(data).then(() => {
              this.$message({
                message: "操作成功",
                type: "success",
                center: true,
              });
              setTimeout(() => {
                this.$router.go(-1);
              }, 1000);
            })
              .catch(err => {
                if (err.code === 201) {
                  this.$message({
                    message: err.message,
                    type: "warning",
                    center: true,
                  });
                }
              });
          }

        } else {
          //-console.log("error submit!!");
          return false;
        }
      });
    },

    delAtt(index) {
      this.indexs = index;
    },

    //分享图片
    shareUpload(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      let arr = [];
      fileList = fileList.map((item) => {
        if (item.response) {
          arr.push({
            url: `https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/${item.response.data}`,
            uid: item.uid,
          });
        } else {
          arr.push(item);
        }
      });
      this.form.coverImg = arr;
    },

    // //商品图片
    goodsUpload(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      let arr = [];
      fileList = fileList.map((item) => {
        if (item.response) {
          arr.push({
            url: `https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/${item.response.data}`,
            uid: item.uid,
          });
        } else {
          arr.push(item);
        }
      });
      this.form.goodPhoto = arr;
      //-console.log(this.form.goodPhoto)
    },

    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    submitType() {
      this.$refs.typeFormData.validate(async vali => {
        if (vali) {
          await addGoodsType({
            typeIcon: this.typeFormData.typeIcon,
            dictValue: this.typeFormData.dictValue,
            name: this.typeFormData.name,
            sort: 0,
            isDel: 0,
          });
          this.getGoodsType();
          this.addTypeVisible = false;
          this.$message({
            type: "success",
            message: "添加成功",
            center: true,
          });
        }
      })
    },
    //新增分类弹窗
    open(row) {
      this.addTypeVisible = true;
      this.typeFormData = {//提交分类数据
        typeIcon: "",
        dictLabel: "",
        name: ""
      };
      this.typeIcon = [];
    },
    prodClose() {
      this.isShow = false;
    },
  },
};
</script>
<style lang="scss" scope>
.disabled .el-upload--picture-card {
  display: none;
}
</style>
